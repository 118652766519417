import {
	computed,
	ref,
} from 'vue';

import {
	initializeChatbot,
	messageChatbot,
} from '@zyro-inc/site-modules/api/ChatbotApi';
import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';

import {
	ChatbotConversationMessage,
	ChatbotRoles,
	ChatbotTexts,
} from '@zyro-inc/site-modules/types';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { CONVERSATION_ID_STORAGE_KEY } from '@zyro-inc/site-modules/constants';

export const useSiteAiAssistant = (isInPreviewMode: boolean) => {
	const {
		siteId,
		blocks,
		pages,
	} = useSiteGlobal();

	const {
		products,
		fetchProducts,
	} = useEcommerceGlobal();

	const uniqueProductIds = computed<string[]>(() => {
		const blocksArr: Array<{ type: string, productIds: string[] }> = Object.values(blocks.value);

		const allProductIds = blocksArr.filter(({ type }) => type === 'BlockEcommerceProductList').flatMap(({ productIds }) => productIds);

		return [...new Set(allProductIds)];
	});

	const context = computed(() => {
		const domainNameWithoutWww = getDomainWithoutWWWPrefix(window.location.hostname);

		const pagesArr = Object.values(pages.value) as Array<{ slug?: string, name?: string }>;
		const preparedPages = pagesArr.filter((page) => page?.name || page?.slug).map(({
			name,
			slug,
		}) => ({
			name,
			slug,
		}));

		const preparedProducts = products.value.map((product) => ({
			title: product.title,
			subtitle: product.subtitle,
			description: product.description,
			variants: product.variants.map((variant) => ({
				title: variant.title,
				prices: variant.prices.map((price) => ({
					amount: price.amount,
					currency: price.currency.name,
				})),
			})),
		}));

		return {
			domain: domainNameWithoutWww,
			pages: preparedPages,
			products: preparedProducts,
		};
	});

	const conversationHistory = ref<ChatbotConversationMessage[]>([]);
	const isChatbotResponding = ref(false);
	const isChatbotRestarting = ref(false);
	const conversationId = ref<string>();
	const isChatbotInitialized = ref(false);

	const initialChatbotMessage = computed<ChatbotConversationMessage[]>(() => ([
		{
			role: ChatbotRoles.assistant,
			content: 'Welcome! 👋\n\nHow can I assist you today?\n\n<p class="chatbot-message__note">Note: I\'m still learning and may provide incorrect information.</p>',
		},
	]));

	const chatbotTexts: ChatbotTexts = {
		main: {
			title: 'AI assistant',
			button: 'Ask Assistant',
			questionInputPlaceholder: 'Type your question',
			tooltipReset: 'Reset',
			tooltipClose: 'Close',
			disclaimer: isInPreviewMode ? 'AI assistant isn\'t available in preview mode. Update your website and visit it to test the AI assistant.' : undefined,
		},
		modalRestart: {
			title: 'Clear chat',
			description: 'After clearing history you won’t be able to access previous chats.',
			cancelButton: 'Cancel',
			confirmButton: 'Confirm',
		},
	};

	const chatbotInitialize = async (initialConversationId?: string) => {
		if (isInPreviewMode) {
			conversationHistory.value = initialChatbotMessage.value;

			return;
		}

		try {
			await fetchProducts(uniqueProductIds.value);

			const data = await initializeChatbot({
				siteId: siteId.value,
				context: context.value,
				conversationId: initialConversationId,
			});

			conversationId.value = data.conversationId;

			conversationHistory.value = data.history.length ? [
				...initialChatbotMessage.value,
				...data.history,
			] : initialChatbotMessage.value;

			window.sessionStorage.setItem(CONVERSATION_ID_STORAGE_KEY, data.conversationId);
			isChatbotInitialized.value = true;
		} catch (error) {
			console.error(error);

			conversationHistory.value = [
				{
					role: ChatbotRoles.system,
					content: 'AI Assistant is not available at this moment. Please try again later.',
				},
			];
		}
	};

	const chatbotStart = async () => {
		if (isChatbotInitialized.value) {
			return;
		}

		const initialConversationId = window.sessionStorage.getItem(CONVERSATION_ID_STORAGE_KEY) || undefined;

		await chatbotInitialize(initialConversationId);
	};

	const chatbotRespond = async (content: string) => {
		if (isChatbotResponding.value || !conversationId.value) {
			return;
		}

		conversationHistory.value = [
			...conversationHistory.value,
			{
				role: ChatbotRoles.user,
				content,
			},
			{
				role: ChatbotRoles.assistant,
			},
		];

		isChatbotResponding.value = true;

		try {
			const data = await messageChatbot({
				siteId: siteId.value,
				conversationId: conversationId.value,
				content,
			});

			conversationHistory.value.pop();

			conversationHistory.value = [
				...conversationHistory.value,
				{
					role: ChatbotRoles.assistant,
					content: data.message,
				},
			];
		} catch (error) {
			console.error(error);

			conversationHistory.value.pop();

			conversationHistory.value = [
				...conversationHistory.value,
				{
					role: ChatbotRoles.system,
					content: 'AI Assistant is not available at this moment. Please try again later.',
				},
			];
		}

		isChatbotResponding.value = false;
	};

	const chatbotRestart = async () => {
		isChatbotRestarting.value = true;

		await chatbotInitialize();

		isChatbotRestarting.value = false;
	};

	return {
		chatbotStart,
		chatbotRespond,
		chatbotRestart,
		conversationHistory,
		isChatbotResponding,
		isChatbotRestarting,
		chatbotTexts,
	};
};
